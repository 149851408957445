import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import { numberWithDelimiter } from '@hello-ai/ar_shared/src/modules/numberWithDelimiter'
import { MediumResource_AffiliateType } from '@hello-ai/proto/src/gen/auto_reserve/affiliate/medium/medium_resource'
import {
  AffiliateResource,
  AffiliateResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/affiliate/medium/medium_service'
import {
  MediumUserResource,
  MediumUserResource_Role,
} from '@hello-ai/proto/src/gen/auto_reserve/affiliate/medium_user/medium_user_resource'
import {
  DestroyRequest,
  UpdateRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/affiliate/medium_user/medium_user_service'
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from 'antd'
import { AffiliateAggregation } from 'components/Medium/AffiliateAggregation'
import { AffiliateReward } from 'components/Medium/AffiliateReward'
import DatePicker from 'components/Shared/DatePicker'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { parse } from 'json2csv'
import { onError, useToken } from 'models/Auth'
import {
  getStatusName,
  mediumClient,
  useMedium,
  useMediumAffiliates,
} from 'models/Medium'
import { mediumUserClient } from 'models/MediumUser'
import { itemRender } from 'modules/itemRender'
import { callRpc, rpcOptions } from 'modules/rpc'
import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { View } from 'react-native'
import { useParams } from 'react-router'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'

const { RangePicker } = DatePicker

const dateFormat = 'YYYY/MM/DD'

const reserveButtonText = t('予約する')

const code = ({
  slug,
  affiliateType,
}: {
  slug: string
  affiliateType: MediumResource_AffiliateType
}) => {
  if (affiliateType === MediumResource_AffiliateType.EMBEDDED) {
    return `
    <script
      id="autoreserve-sdk"
      async
      defer
      src="https://sdk.autoreserve.com/autoreserve.min.js?medium_slug=${slug}"
      charset="utf-8"
    ></script>
    <a
      class="autoreserve-reserve-button"
      href="https://autoreserve.com/restaurants/M7XHuRBVcR32NK3h2bAm"
      data-width="100px"
      data-height="36px"
      data-font-size="14px"
      data-logo-type="light"
      data-text-color="white"
      data-background-color="#AB935A"
    >${reserveButtonText}</a>
    `
  } else {
    return `?medium_slug=${slug}`
  }
}

export default function Medium() {
  const token = useToken()
  const [form] = Form.useForm()

  const [affiliateStartDate, setAffiliateStartDate] = useState(
    dayjs().subtract(2, 'weeks').format(dateFormat)
  )
  const [affiliateEndDate, setAffiliateEndDate] = useState(
    dayjs().subtract(1, 'days').format(dateFormat)
  )
  const [affiliateStatuses, setAffiliateStatuses] = useState<
    Array<AffiliateResource_Status>
  >([])
  const [sortColumn, setSortColumn] = useState('created_at')

  const { mediumId } = useParams<{ mediumId: string }>()
  const { medium, mutate } = useMedium({ id: mediumId! })
  const [page, setPage] = useState<number>(1)

  const per = 20
  const { data } = useMediumAffiliates({
    id: mediumId!,
    startDate: affiliateStartDate,
    endDate: affiliateEndDate,
    statuses: affiliateStatuses,
    sortColumn,
    page,
    per,
    isDownload: false,
  })

  if (medium === undefined) return null

  const updateMediumUser = async (params: UpdateRequest) => {
    const { error } = await callRpc(
      mediumUserClient.update(params, rpcOptions({ token }))
    )

    if (error != null) {
      onError(error)
      return
    }

    mutate()

    message.success(t('更新しました'))
  }

  const destroyMediumUser = (params: DestroyRequest) => {
    Modal.confirm({
      title: t('削除します'),
      content: t('本当によろしいでしょうか？'),
      okText: t('はい'),
      cancelText: t('いいえ'),
      async onOk() {
        const { error } = await callRpc(
          mediumUserClient.destroy(params, rpcOptions({ token }))
        )

        if (error != null) {
          onError(error)
          return
        }

        mutate()

        message.success(t('削除しました'))
      },
    })
  }

  const inviteUserMedium = (values: { username: string }) => {
    const params = {
      username: values.username,
      mediumId: medium.id,
    }
    Modal.confirm({
      title: t('招待します'),
      content: t('本当によろしいでしょうか？'),
      okText: t('はい'),
      cancelText: t('いいえ'),
      async onOk() {
        const { error } = await callRpc(
          mediumUserClient.invite(params, rpcOptions({ token }))
        )

        if (error != null) {
          onError(error)
          return
        }

        mutate()
        form.resetFields()

        message.success(t('招待しました'))
      },
    })
  }

  const download = async (fields: { value: string; label: string }[]) => {
    const { response, error } = await callRpc(
      mediumClient.affiliates(
        {
          id: mediumId!,
          startDate: affiliateStartDate,
          endDate: affiliateEndDate,
          statuses: affiliateStatuses,
          sortColumn,
          page,
          per,
          isDownload: true,
        },
        rpcOptions({ token })
      )
    )

    if (error) {
      onError(error)
      return
    }

    if (response) {
      makeCsv(response.affiliates, fields)
    }
  }

  const makeCsv = (
    affiliates: AffiliateResource[],
    fields: { value: string; label: string }[]
  ) => {
    const csv = parse(affiliates, { fields })
    const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })

    saveAs(blob, `${medium.name}-affiliates.csv`, {
      autoBom: true,
    })
  }

  const helpPageUrl = (affiliateType: MediumResource_AffiliateType) => {
    if (affiliateType === MediumResource_AffiliateType.EMBEDDED) {
      return 'https://helloincai.notion.site/b3f46608188d4aa0bf74e0cfca1b2491'
    } else {
      return 'https://help.autoreserve.com/hc/articles/34039667572121-AutoReserve-Affiliate-%E3%83%AA%E3%83%B3%E3%82%AF%E8%A8%AD%E7%BD%AE%E6%96%B9%E6%B3%95'
    }
  }

  return (
    <View>
      <Breadcrumb
        itemRender={itemRender}
        routes={[
          {
            path: '/media',
            breadcrumbName: t('メディア一覧'),
          },
          {
            path: `/media/${medium.id}`,
            breadcrumbName: medium.name,
          },
        ]}
      />
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <PageHeader
            ghost={false}
            title={medium.name}
            subTitle={getStatusName(medium.status)}
            style={{ marginTop: 32 }}
          >
            <Descriptions>
              <Descriptions.Item label={'URL'}>
                {medium.url || medium.domain}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions>
              <Descriptions.Item label={'slug'}>
                {medium.slug}
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <PageHeader
            ghost={false}
            title={t('ユーザー一覧')}
            style={{ marginTop: 32 }}
          >
            <Table
              rowKey={(record) => record.id}
              columns={[
                {
                  title: (
                    <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                      {t('ユーザーID')}
                    </Typography.Text>
                  ),
                  dataIndex: ['user', 'username'],
                  key: 'username',
                },
                {
                  title: t('名前'),
                  dataIndex: ['user', 'name'],
                  key: 'name',
                },
                {
                  title: t('メールアドレス'),
                  dataIndex: ['user', 'email'],
                  key: 'email',
                },
                {
                  title: t('アクション'),
                  key: 'action',
                  render: (_, mediumUser: MediumUserResource) => (
                    <Space size="middle">
                      <Select
                        defaultValue={mediumUser.role}
                        onChange={(value) =>
                          updateMediumUser({
                            id: mediumUser.id,
                            mediumId: medium.id,
                            role: value,
                          })
                        }
                      >
                        <Select.Option value={MediumUserResource_Role.ADMIN}>
                          {t('管理者')}
                        </Select.Option>
                        <Select.Option value={MediumUserResource_Role.NORMAL}>
                          {t('一般')}
                        </Select.Option>
                      </Select>
                      <Button
                        danger
                        onClick={() =>
                          destroyMediumUser({
                            id: mediumUser.id,
                            mediumId: medium.id,
                          })
                        }
                      >
                        {t('削除')}
                      </Button>
                    </Space>
                  ),
                },
              ]}
              dataSource={medium.mediumUsers}
              pagination={false}
              scroll={{ x: true }}
            />
            <Form
              style={{ marginTop: 32 }}
              layout="inline"
              onFinish={inviteUserMedium}
              form={form}
            >
              <Form.Item name="username">
                <Input addonBefore="@" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t('招待')}
                </Button>
              </Form.Item>
            </Form>
          </PageHeader>
          <PageHeader
            ghost={false}
            title={
              medium.affiliateType === MediumResource_AffiliateType.EMBEDDED
                ? t('埋め込みコード')
                : t('リンクパラメータ')
            }
            style={{ marginTop: 32 }}
            extra={[
              <CopyToClipboard
                text={code({
                  slug: medium.slug,
                  affiliateType: medium.affiliateType,
                })}
                key="copy"
                onCopy={() => message.success(t('コピーしました'))}
              >
                <Button>{t('コピー')}</Button>
              </CopyToClipboard>,
            ]}
          >
            <a
              href={helpPageUrl(medium.affiliateType)}
              target="_blank"
              rel="noreferrer"
            >
              {t('詳しい使用法はこちら')}
            </a>
            {medium.affiliateType === MediumResource_AffiliateType.EMBEDDED && (
              <View style={{ marginTop: 16 }}>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {code({
                    slug: medium.slug,
                    affiliateType: medium.affiliateType,
                  })}
                </SyntaxHighlighter>
              </View>
            )}
            {medium.affiliateType === MediumResource_AffiliateType.LINK && (
              <View style={{ marginTop: 16 }}>
                <Typography.Text>ex:</Typography.Text>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {`https://example.com?medium_slug=${medium.slug}`}
                </SyntaxHighlighter>
              </View>
            )}
          </PageHeader>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 16 }}>
          <PageHeader
            ghost={false}
            title={t('成果報酬一覧画面')}
            style={{ marginTop: 32 }}
          >
            <Row style={{ marginTop: 4, marginBottom: 8 }}>
              <Typography.Text style={{ marginRight: 20 }}>
                {' '}
                {t('成果発生日')}
              </Typography.Text>
              <RangePicker
                key="range"
                defaultValue={[
                  dayjs(affiliateStartDate, dateFormat),
                  dayjs(affiliateEndDate, dateFormat),
                ]}
                format={dateFormat}
                onChange={(_, dateStrings) => {
                  setAffiliateStartDate(dateStrings[0])
                  setAffiliateEndDate(dateStrings[1])
                }}
              />
            </Row>
            <Row style={{ marginTop: 8, marginBottom: 8 }}>
              <Typography.Text style={{ marginRight: 20 }}>
                {' '}
                {t('ステータス')}
              </Typography.Text>
              <Checkbox.Group
                options={[
                  {
                    label: t('未承認'),
                    value: AffiliateResource_Status.UNAPPROVED,
                  },
                  {
                    label: t('承認'),
                    value: AffiliateResource_Status.APPROVED,
                  },
                  { label: t('否認'), value: AffiliateResource_Status.DENIAL },
                ]}
                defaultValue={affiliateStatuses}
                onChange={(statuses) => {
                  setAffiliateStatuses(statuses as number[])
                  setPage(1) // ステータス変更時は1ページ目から取り直す
                }}
              />
            </Row>
            <Row justify="end" style={{ marginTop: 8, marginBottom: 8 }}>
              <Button
                color="primary"
                onClick={() => {
                  download([
                    { value: 'affiliatedAt', label: t('発生日') },
                    { value: 'approvedAt', label: t('確定日') },
                    { value: 'statusStr', label: t('ステータス') },
                    { value: 'amount', label: t('報酬') },
                  ])
                }}
              >
                {t('ダウンロード')}
              </Button>
            </Row>
            <Table
              rowKey={(record) => record.index}
              columns={[
                {
                  title: t('発生日'),
                  dataIndex: 'affiliatedAt',
                  key: 'affiliatedAt',
                  render: (value) => {
                    return (
                      <Typography.Text>
                        {' '}
                        {dayjs(value).format('YYYY-MM-DD')}{' '}
                      </Typography.Text>
                    )
                  },
                },
                {
                  title: t('確定日'),
                  dataIndex: 'approvedAt',
                  key: 'approvedAt',
                  render: (value) => {
                    if (value === undefined || value === '') {
                      return <Typography.Text> {''} </Typography.Text>
                    }

                    return (
                      <Typography.Text>
                        {' '}
                        {dayjs(value).format('YYYY-MM-DD')}{' '}
                      </Typography.Text>
                    )
                  },
                },
                {
                  title: t('ステータス'),
                  dataIndex: 'statusStr',
                  key: 'statusStr',
                },
                {
                  title: t('報酬'),
                  dataIndex: 'amount',
                  key: 'amount',
                  render: (amount) => {
                    return (
                      <Typography.Text>
                        {' '}
                        {t('{{amount}}円', {
                          amount: numberWithDelimiter(amount),
                        })}{' '}
                      </Typography.Text>
                    )
                  },
                },
              ]}
              dataSource={data?.affiliates}
              pagination={{
                position: ['bottomLeft'],
                pageSize: per,
                total: data?.totalCount,
              }}
              onChange={(pagination, _) => setPage(pagination.current ?? 1)}
            />
          </PageHeader>
          <AffiliateAggregation
            title={t('日別成果集計・月別成果集計')}
            medium={medium}
          />
          <AffiliateReward title={t('報酬レポート')} medium={medium} />
        </Col>
      </Row>
    </View>
  )
}
